class UWImpress {
  static TimeoutWrapperOption :TimeoutWrapperOption = {
    delayMSec: 0,
    activateClass: 'actived',
  }

  static TimeoutWrapperBase (
    targetElement: HTMLElement,
    delayMSec :number,
    activateClass :string,
  ) :void {
    setTimeout(() => {
      targetElement.classList.add(activateClass);
    }, delayMSec);
    return
  }

  static TimeoutWrapperSelector (
    targetSelector: string,
    delayMSec :number,
    activateClass :string,
  ) :void {
    const observer = new MutationObserver(records => {
      records.forEach(record => {
        const elms = document.querySelectorAll(targetSelector)
        Array.from(elms).forEach(elm => {
          if(elm !== null && !elm.classList.contains(activateClass)){
            UWImpress.TimeoutWrapperBase(elm as HTMLElement, delayMSec, activateClass)
            return
          }
        })
      })
    })

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['class', 'style'],
      subtree: true,
      childList: true,
    });
  }
  
}

export default UWImpress;
