class UWTool {
  static setParamsAccess(
    paramName :string,
    actCondition :Array<any>,
    action: Function
  ) :void {
    const query = new URL(location.href).searchParams
    const accessParam = query.get(paramName)
    if(accessParam === null || accessParam.length === 0){
      return
    }

    accessParam.split(',').forEach(p => {
      const condition = actCondition.find((c :any) => c.param === p)
      action(condition?.element as HTMLElement)
    })
  }

  static setParamsURL(
    paramName :string,
    params :Array<string>,
  ){
    const url = new URL(location.href)
    if(params.length === 0){
      url.searchParams.delete(paramName)
    }else{
      url.searchParams.set(paramName, params.join(','))
    }

    history.replaceState('', '', url.href)
  }

  static setRadioClass(
    targetElements :Array<HTMLElement>,
    radioClass :string,
  ) :void {
    const observer = new MutationObserver(records => {
      records.forEach(record => {
        const recordContains = (record.target as HTMLElement).classList.contains(radioClass);
        const othersContains = Array.from(targetElements).filter((elm) => {
          return (elm !== record.target) && (elm as HTMLElement).classList.contains(radioClass);
        });
        
        if (recordContains && 0 < othersContains.length){
          Array.from(othersContains).forEach((other) => {
            (other as HTMLElement).classList.remove(radioClass);
          });
        }
      });
    });
    Array.from(targetElements).forEach((elm) => {
      observer.observe(elm, {
        attributes: true,
        attributeFilter: ['class'],
      });
    });
  }

  static setStyleVariable(
    elmId: string,
    varsObj: {[name: string]: any},
  ){
    let styleElm = document.querySelector(`#${elmId}`) as HTMLStyleElement;
    let baseStyles :{[key: string] :string} = {}
    if(styleElm === null){
      styleElm = document.createElement('style');
      styleElm.type = 'text/css';
      styleElm.id = elmId;
      document.head.insertAdjacentElement('afterbegin', styleElm);
    }else{
      const innerStyleText = styleElm.innerText
        .replace(':root', '')
        .replaceAll(' ', '')
        .replaceAll('{', '')
        .replaceAll('}', '')
        .replaceAll('\n', '')
      innerStyleText.split(';').filter(f => f.includes(':'))
        .forEach(s => {
          const arr = s.split(':')
          baseStyles[arr[0]] = arr[1]
        })
    }
    const mergedStyle = {
      ...baseStyles,
      ...varsObj,
    }

    const styleArray = Object.keys(mergedStyle).map(key => `${key}: ${mergedStyle[key]};\n`)
    styleElm.textContent = `\n:root{\n${styleArray.join('')}}\n`;
  }

}

export default UWTool;
