/// <reference path="../types/config.d.ts" />

import 'regenerator-runtime'
import UWTool from '../lib/uw-tool';
import UWClick from '../lib/uw-click';
import UWScroll from '../lib/uw-scroll';
import UWImpress from '../lib/uw-impress';

class UWX {
  static MoreContentsAll(moreSelector :string, hideSelector :string, hideClass :string = 'hide') :void {
    const targets = Array.from(document.querySelectorAll(`${hideSelector}.${hideClass}`));
    UWClick.setMoreContentsBase(
      document.querySelector(moreSelector) as HTMLElement,
      targets as Array<HTMLElement>,
      hideClass,
      targets.length,
    );
  }

  static MoreContentsConst(moreSelector :string, hideSelector :string , count :number = 1, hideClass :string = 'hide') :void {
    UWClick.setMoreContentsBase(
      document.querySelector(moreSelector) as HTMLElement,
      Array.from(document.querySelectorAll(`${hideSelector}.${hideClass}`)) as Array<HTMLElement>,
      hideClass,
      count,
    );
  }

  static MenuActivate(leadSelector :string, targetSelector :string, activeClass :string = 'actived'){
    Array.from(document.querySelectorAll(leadSelector)).forEach((leadElement) => {
      UWClick.setTargetActionBase(
        leadElement as HTMLElement,
        activeClass,
        (lead :HTMLElement) => {
          Array.from(document.querySelectorAll(targetSelector)).forEach((target) => {
            target.classList.add(activeClass);
          });
        },
        (lead :HTMLElement) => {
          Array.from(document.querySelectorAll(targetSelector)).forEach((target) => {
            target.classList.remove(activeClass);
          });
        },
      );
    });
  }

  static MenuNext(leadSelector :string, activeClass :string = 'actived') :void {
    Array.from(document.querySelectorAll(leadSelector)).forEach((leadElement) => {
      UWClick.setTargetActionBase(
        leadElement as HTMLElement,
        activeClass,
        (lead :HTMLElement) => {
          const baseY = window.pageYOffset;
          lead.nextElementSibling?.classList.add(activeClass);
          scrollTo(window.pageXOffset, baseY);
        },
        (lead :HTMLElement) => {
          const baseY = window.pageYOffset;
          lead.nextElementSibling?.classList.remove(activeClass);
          scrollTo(window.pageXOffset, baseY);
        },
      );
    });
  }

  static MenuNth(leadSelector :string, followSelector :string, activeClass :string = 'actived') :void {
    UWClick.setPairActionNthBase(
      Array.from(document.querySelectorAll(leadSelector)),
      Array.from(document.querySelectorAll(followSelector)),
      activeClass
    );
  }

  static ClickAction(targetSelector :string, activeClass :string = 'actived', once :boolean = false) :void {
    Array.from(document.querySelectorAll(targetSelector)).forEach((targetElement) => {
      UWClick.setClickActionBase(
        targetElement as HTMLElement,
        activeClass,
        once,
      )
    })
  }

  static ClickTransfer(srcSelector :string, destSelector :string) {
    Array.from(document.querySelectorAll(srcSelector)).forEach((srcElement) => {
      Array.from(document.querySelectorAll(destSelector)).forEach((destElement) => {
        UWClick.setClickTransferBase(
          srcElement as HTMLElement,
          destElement as HTMLElement,
        );
      });
    });
  }

  static SearchParamAccess(queryName :string, actCondition :Array<ParamAccessOption>) :void {
    const conditions = actCondition.map((c) => {
      return {
        param: c.param,
        element: document.querySelector(c.selector) as HTMLElement
      }
    });

    UWTool.setParamsAccess(queryName, conditions, (element :HTMLElement) => {
      element.click();
    });
  }

  static SearchParamURL(queryName :string, actCondition :Array<ParamAccessOption>, activeClass :string = 'actived'){
    const observer = new MutationObserver(records => {
      records.forEach(record => {
        const recordContains = (record.target as HTMLElement).classList.contains(activeClass);
        const recordOldContains = record.oldValue === null ? false : record.oldValue.includes(activeClass);

        if (recordContains !== recordOldContains){
          let params :Array<string> = []
          actCondition.forEach(c => {
            const elm = document.querySelector(c.selector) as HTMLElement
            if(elm.classList.contains(activeClass)){
              params.push(c.param)
            }
          })

          UWTool.setParamsURL(queryName, params)
        }
      })
    })

    actCondition.map(c => document.querySelector(c.selector)).forEach(elm => {
      observer.observe(elm as Node, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class'],
      })
    })
  }

  static PosYCheck(selector :string) :void {
    UWScroll.applyPositionY((result :any) => {
      const content = `${result.upper}px / ${result.lower}px`;
      (document.querySelector(selector) as HTMLElement).textContent = content;
    });
  }

  static ScrollActionReferPosition(
    activateSelector :string,
    ignitionLine :number,
    option :ReferPositionOption
  ) :void {
    const opt = { ...UWScroll.ReferPositionOption, ...option };
    Array.from(document.querySelectorAll(activateSelector)).forEach((activateElement) => {
      UWScroll.setScrollActionPositionBase(
        activateElement as HTMLElement,
        ignitionLine,
        opt.sedationLine,
        opt.activeClass,
        opt.once,
        opt.direction,
        opt.suspend,
      );
    });
  }

  static ScrollActionReferElement(
    activateSelector :string,
    referSelector :string,
    option :ReferElementOption
  ) :void {
    const opt = { ...UWScroll.ReferElementOption, ...option };
    Array.from(document.querySelectorAll(activateSelector)).forEach((activateElement) => {
      UWScroll.setScrollActionElementBase(
        activateElement as HTMLElement,
        document.querySelector(referSelector) as HTMLElement,
        opt.rootMargin,
        opt.timing,
        opt.activeClass,
        opt.once,
        opt.direction
      );
    });
  }

  static ScrollActionSelfElement (
    referSelector :string,
    option :ReferElementOption
  ){
    const opt = { ...UWScroll.ReferElementOption, ...option };
    Array.from(document.querySelectorAll(referSelector)).forEach((referElement) => {
      UWScroll.setScrollActionElementBase(
        referElement as HTMLElement,
        referElement as HTMLElement,
        opt.rootMargin,
        opt.timing,
        opt.activeClass,
        opt.once,
        opt.direction
      );
    });
  }

  static ScrollLock (
    target :HTMLElement | string,
    activateClass :string = 'actived'
  ) {
    const elm = (typeof target === 'string') ? document.querySelector(target) as HTMLElement : target;
    UWScroll.setScrollLock(elm, activateClass);
  }

  static RadioClass (
    targets :Array<HTMLElement> | string, 
    radioClass :string = 'actived'
  ) :void {
    const elements = (typeof targets === 'string') ? Array.from(document.querySelectorAll(targets)) : targets;
    UWTool.setRadioClass(elements as Array<HTMLElement>, radioClass);
  }

  static Waiting (
    target :Array<HTMLElement> | string,
    option :TimeoutWrapperOption
  ) :void {
    const opt = { ...UWImpress.TimeoutWrapperOption, ...option };
    if(typeof target !== 'string' && target.length){
      Array.from(target as Array<HTMLElement>).forEach(elm => {
        if(elm !== null){
          UWImpress.TimeoutWrapperBase(elm, opt.delayMSec, opt.activateClass);
        }
      })
    }else{
      UWImpress.TimeoutWrapperSelector(target as string, opt.delayMSec, opt.activateClass);
    }
  }

  static PageScript (
    path :string,
    scripts :Function,
    args :object = {}
  ) {
    if(location.pathname.includes(path)){
      scripts(args)
    }
  }

  static ScriptSwitch (
    matchMin :number,
    matchExec :Function,
    unmatchExec :Function
  ) {
    if(matchMin <= window.innerWidth){
      matchExec()
    }else{
      unmatchExec()
    }
  }

  static ElementSwitch (
    matchMin :number,
    parentSelector :string,
    matchElmStr :string,
    unmatchElmStr :string
  ) {
    UWX.ScriptSwitch(matchMin,
      () => {
        Array.from(document.querySelectorAll(parentSelector)).forEach(p => {
          p.innerHTML = ''
          p.insertAdjacentHTML('afterbegin', matchElmStr)
        })
      },
      () => {
        Array.from(document.querySelectorAll(parentSelector)).forEach(p => {
          p.innerHTML = ''
          p.insertAdjacentHTML('afterbegin', unmatchElmStr)
        })
      }
    )
  }

  static InliningSvg (targetSelector :string, afterExec :Function = () => {}) {
    Array.from(document.querySelectorAll(targetSelector)).forEach(target => {
      const imgTarget = target as HTMLImageElement
      const imgClass = imgTarget.classList
      const imgAlt = imgTarget.alt

      const imgTemp = document.createElement('img')
      imgTemp.addEventListener('load', () => {
        fetch(imgTarget.src).then(data => data.text()).then(text => {
          imgTarget.insertAdjacentHTML('afterend', text)
          let elm = imgTarget.nextSibling as Element
          while (elm.tagName === void 0) {
            const next = elm.nextSibling as Element
            elm.remove()
            elm = next
          }
          elm.insertAdjacentHTML('afterbegin', `<title>${imgAlt}</title>`)
          Array.from(imgClass).forEach(c => {
            elm.classList.add(c)
          })
    
          imgTarget.remove()
          afterExec()
        })
      })
      imgTemp.src = imgTarget.src
    })
  }

  static async SharingURL(
    url :string,
    triggerSelector: string,
    copiedSelector: string,
    parentSelector :string,
    activateClass :string = 'actived',
    message :string = 'share URL',
  ) {
    Array.from(document.querySelectorAll(`${triggerSelector}`)).forEach(triggerElm => {
      triggerElm.addEventListener('click', async () => {
        if(navigator.share){
          await navigator.share({
            text: message,
            url: url,
          })
        }else{
          navigator.clipboard.writeText(url)
          const copiedElement = document.querySelector(`${copiedSelector}`) as HTMLElement
          const parentElement = document.querySelector(`${parentSelector}`) as HTMLElement
    
          copiedElement.classList.add(activateClass)
          const handler = () => {
            parentElement.removeEventListener('mouseleave', handler)
            copiedElement.classList.remove(activateClass)
          }
          parentElement.addEventListener('mouseleave', handler)
        }
      })
    })
  }

  static ElementReplace(
    targetSelector: string,
    landmarkSelector: string,
    position: 'beforebegin' | 'afterbegin' | 'beforeend' | 'afterend',
  ){
    const targetElements = Array.from(document.querySelectorAll(targetSelector))
    const landmarkElement = document.querySelector(landmarkSelector)
    const sortedElements = position.includes('after') ? [...targetElements].reverse() : targetElements
    sortedElements.forEach(elm => {
      landmarkElement?.insertAdjacentElement(position, elm)
    })
  }


}

export default UWX;
