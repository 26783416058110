class UWClick {
  static setMoreContentsBase(
    moreElement :HTMLElement,
    targetElements :Array<HTMLElement>,
    hideClass :string,
    count: number,
    action :Function = (elm :HTMLElement) => {}
  ) :void {
    moreElement.addEventListener('click', () => {
      const XOffset = window.pageXOffset;
      const YOffset = window.pageYOffset;

      const hideElements = targetElements.filter((t) => t.classList.contains(hideClass));
      const targetCount = Math.min(count, hideElements.length);

      hideElements.splice(0, targetCount).forEach((target) => {
        action(target);
        target.classList.remove(hideClass);
      });

      const reloadHided = targetElements.filter((t) => t.classList.contains(hideClass));
      if(reloadHided.length === 0){
        moreElement.style.display = 'none';
      }

      window.scrollTo(XOffset, YOffset);
    });
  }

  static setTargetActionBase(
    leadElement :HTMLElement,
    activeClass :string,
    actAction :Function = (elm :HTMLElement) => {},
    deactAction :Function = (elm :HTMLElement) => {}
  ) :void {
    leadElement.addEventListener('click', () => {
      if(leadElement.classList.contains(activeClass)){
        deactAction(leadElement);
        leadElement.classList.remove(activeClass);
      }else{
        actAction(leadElement);
        leadElement.classList.add(activeClass);
      }
    });
  }

  static setPairActionNthBase(
    leadElements :Array<HTMLElement>,
    followElements :Array<HTMLElement>,
    activeClass :string,
    leadAction :Function = (elm :HTMLElement) => {},
    followAction :Function = (elm :HTMLElement) => {}
  ) :void {
    leadElements.forEach((lead) => {
      lead.addEventListener('click', () => {
        if(lead.classList.contains(activeClass)){
          return;
        }

        const n = leadElements.findIndex(t => t === lead);
        const follow = followElements[n];

        leadElements.find(t => t.classList.contains(activeClass))?.classList.remove(activeClass);
        leadAction(lead);
        lead.classList.add(activeClass);

        followElements.find(t => t.classList.contains(activeClass))?.classList.remove(activeClass);
        followAction(follow);
        follow.classList.add(activeClass);
      });
    });
  }

  static setClickActionBase (
    targetElement :HTMLElement,
    activeClass :string,
    once :boolean = false,
    actAction :Function = (elm :HTMLElement) => {},
    deactAction :Function = (elm :HTMLElement) => {},
  ) {
    targetElement.addEventListener('click', () => {
      if(!targetElement.classList.contains(activeClass)){
        targetElement.classList.add(activeClass)
        actAction(targetElement)
      }else if(!once){
        targetElement.classList.remove(activeClass)
        deactAction(targetElement)
      }
    })
  
  }

  static setClickTransferBase (
    srcElement :HTMLElement,
    destElement :HTMLElement,
    srcAction :Function = (elm :HTMLElement) => {},
    destAction :Function = (elm :HTMLElement) => {},
  ) {
    srcElement.addEventListener('click', () => {
      destElement.click();
      srcAction(srcElement);
      destAction(destElement);
    });
  }
}

export default UWClick;
